<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Edit Data Surat Kuasa</h4>
          </template>
          <template v-slot:body>
            <div class="d-inline-block float-right">
              <router-link
                class="btn btn-secondary dark-btn-primary"
                :to="{ name: 'kerjasama.jenisDokumen' }"
                role="button"
                ><i class="ri-arrow-left-line"></i>Kembali</router-link
              >
            </div>
            <div class="align-items-center mt-lg-5">
              <!-- <b-form> -->
              <div>
                <b-form-group>
                  <label for="nomorSuratKuasa">Nomor Surat Kuasa:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="nomorSuratKuasa"
                    v-model="form.nomorSuratKuasa"
                    required
                    placeholder="Nomor Surat Kuasa"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="tanggalSurat">Tanggal Surat:</label
                  ><span class="text-danger ml-1">*</span>
                  <flat-pickr
                    id="tanggalSurat"
                    ref="refTanggalSurat"
                    class="form-control bg-white"
                    :config="basicConfig"
                    v-model="form.tanggalSurat"
                    placeholder="Tanggal Surat"
                    required
                  ></flat-pickr>
                </b-form-group>
                <b-row class="mt-4">
                  <!--  PIHAK PERTAMA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK PERTAMA</h4>
                    <b-form-group>
                      <label for="namaPihakSatu">Nama Pihak Pertama:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaPihakSatu"
                        v-model="form.namaPihakSatu"
                        placeholder="Nama Pihak Pertama"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakSatu">Jabatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanPihakSatu"
                        v-model="form.jabatanPihakSatu"
                        placeholder="Jabatan"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakSatu">Instansi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="instansiPihakSatu"
                        v-model="form.instansiPihakSatu"
                        placeholder="Instansi"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="nipPihakSatu">NIP:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nipPihakSatu"
                        v-model="form.nipPihakSatu"
                        placeholder="NIP"
                        required
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="alamatPihakSatu">Alamat:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="alamatPihakSatu"
                        v-model="form.alamatPihakSatu"
                        required
                        type="textarea"
                        max-length="255"
                      />
                    </b-form-group> -->
                  </b-col>
                  <!-- PIHAK KEDUA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK KEDUA</h4>
                    <b-form-group>
                      <label for="namaPihakDua">Nama Pihak Kedua:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="namaPihakDua"
                        v-model="form.namaPihakDua"
                        placeholder="Nama Pihak Kedua"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakDua">Jabatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="jabatanPihakDua"
                        v-model="form.jabatanPihakDua"
                        placeholder="Jabatan"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakDua">Instansi:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="instansiPihakDua"
                        v-model="form.instansiPihakDua"
                        placeholder="Instansi"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="nipPihakDua">NIP:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nipPihakDua"
                        v-model="form.nipPihakDua"
                        placeholder="NIP"
                        required
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="alamatPihakDua">Alamat:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="alamatPihakDua"
                        v-model="form.alamatPihakDua"
                        type="textarea"
                        max-length="255"
                        required
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- KEGIATAN -->
                  <b-col>
                    <h4 class="mb-2">KEGIATAN</h4>
                    <b-form-group>
                      <label for="namaKegiatan">Nama Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaKegiatan"
                        v-model="form.namaKegiatan"
                        required
                        placeholder="Nama Kegiatan"
                        max-length="255"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="judulKegiatan">Judul Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="judulKegiatan"
                        v-model="form.judulKegiatan"
                        placeholder="Judul Kegiatan"
                        max-length="255"
                        required
                      />
                    </b-form-group>
                    <b-form-row>
                      <b-col lg="4">
                        <b-form-group>
                          <label for="tanggalAwalKegiatan"
                            >Tanggal Mulai Kegiatan:</label
                          ><span class="text-danger ml-1">*</span>
                          <flat-pickr
                            id="tanggalAwalKegiatan"
                            ref="refTanggalAwalKegiatan"
                            class="form-control bg-white"
                            :config="basicConfig"
                            v-model="form.tanggalAwalKegiatan"
                            placeholder="Tanggal Mulai Kegiatan"
                            :isError="!isValidTanggalAwal"
                            @input="inputTanggalSk"
                            required
                          ></flat-pickr>
                          <collapse-transition>
                            <div
                              class="text-danger mb-2"
                              v-show="
                                isError.tanggalKegiatan.validTanggalAwalKegiatan
                              "
                            >
                              Tanggal Mulai Kegiatan tidak bisa lebih dari
                              Tanggal Selesai Kegiatan.
                            </div>
                          </collapse-transition>
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <div v-if="!form.tanggalAwalKegiatan">
                          <b-form-group>
                            <label>Tanggal Akhir Kegiatan:</label
                            ><span class="text-danger ml-1">*</span>
                            <input-primary
                              class="bg-light cursor-not-allowed"
                              v-b-tooltip="
                                'Masukkan Tanggal Mulai Kegiatan lebih dulu'
                              "
                              placeholder="Masukkan Tanggal Mulai Kegiatan lebih dulu"
                              disabled
                            />
                          </b-form-group>
                        </div>
                        <div v-else>
                          <b-form-group>
                            <label for="tanggalAkhirKegiatan"
                              >Tanggal Selesai Kegiatan:</label
                            ><span class="text-danger ml-1">*</span>
                            <flat-pickr
                              id="tanggalAkhirKegiatan"
                              ref="refTanggalAkhirKegiatan"
                              class="form-control bg-white"
                              :config="basicConfig"
                              v-model="form.tanggalAkhirKegiatan"
                              placeholder="Tanggal Selesai Kegiatan"
                              :isError="!isValidTanggalAkhir"
                              @input="inputTanggalSk"
                              required
                            ></flat-pickr>
                            <collapse-transition>
                              <div
                                class="text-danger mb-2"
                                v-show="
                                  isError.tanggalKegiatan
                                    .validTanggalAkhirKegiatan
                                "
                              >
                                Tanggal Selesai Kegiatan tidak bisa kurang dari
                                Tanggal Mulai Kegiatan.
                              </div>
                            </collapse-transition>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-form-row>
                    <b-form-group>
                      <label for="tempatKegiatan">Tempat Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tempatKegiatan"
                        v-model="form.tempatKegiatan"
                        placeholder="Tempat Kegiatan"
                        required
                      />
                    </b-form-group>
                    <!-- Lokasi Dokumen -->
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="lokasiDokumenSk">File Dokumen:</label>
                          <b-form-file
                            accept=".pdf"
                            placeholder="Pilih Dokumen"
                            id="lokasiDokumenSk"
                            v-model="form.lokasiDokumenSk"
                            @input="inputUploadDokumen"
                          ></b-form-file>
                        </b-form-group>
                      </b-col>
                      <b-col
                        class="align-self-center mt-2"
                        :class="
                          dataDokumenKs
                            ? 'cursor-pointer'
                            : 'cursor-not-allowed'
                        "
                        md="4"
                        v-b-tooltip="
                          dataDokumenKs
                            ? 'Lihat Dokumen'
                            : 'File tidak ditemukan'
                        "
                      >
                        <b-button
                          v-if="dataDokumenKs"
                          variant="primary"
                          @click="showDokumenKs"
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                        <b-button v-else variant="default" disabled
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Validasi Form -->
                <b-row class="mt-5">
                  <b-col>
                    <transition name="hint" appear>
                      <div class="text-justify iq-bg-primary text-dark p-4">
                        <div class="w-full flex flex-wrap">
                          <!-- Status lengkap form -->
                          <div>
                            <h5 class="font-weight-bold">
                              Data Wajib Yang Belum Terisi:
                            </h5>
                            <div v-if="formValidation.errors.length > 0">
                              <div
                                v-for="error in formValidation.errors"
                                :key="error.content"
                              >
                                <div class="p-1">
                                  <span
                                    class="
                                      cursor-pointer
                                      font-weight-bold
                                      text-primary
                                    "
                                    @click="inputFocus(error.model)"
                                    >• {{ error.content }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div v-if="!formValidation.errors.length > 0">
                              <div class="text-success">Form Sudah Lengkap</div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 text-primary">
                          * Cek kembali pengisian form jika tidak dapat
                          menyimpan data
                        </div>
                      </div>
                    </transition>
                  </b-col>
                </b-row>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <router-link
                      class="btn btn-secondary dark-btn-primary btn-block"
                      :to="{ name: 'kerjasama.jenisDokumen' }"
                      role="button"
                      ><i class="ri-arrow-left-line"></i>Kembali</router-link
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      class="btn-block"
                      variant="success"
                      type="submit"
                      @click="createData"
                      :disabled="!isValidForm"
                      ><i class="ri-save-line"></i>Simpan</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <!-- </b-form> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'kerjasama.sk.edit',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.nomorSuratKuasa &&
        this.form.tanggalSurat &&
        // PIHAK PERTAMA
        this.form.namaPihakSatu &&
        this.form.jabatanPihakSatu &&
        this.form.instansiPihakSatu &&
        // this.form.alamatPihakSatu &&
        this.form.nipPihakSatu &&
        // PIHAK KEDUA
        this.form.namaPihakDua &&
        this.form.jabatanPihakDua &&
        this.form.instansiPihakDua &&
        // this.form.alamatPihakDua &&
        this.form.nipPihakDua &&
        // KEGIATAN
        this.form.namaKegiatan &&
        this.form.judulKegiatan &&
        this.form.tanggalAwalKegiatan &&
        this.form.tanggalAkhirKegiatan &&
        this.form.tempatKegiatan &&
        this.isValidTanggalAwal &&
        this.isValidTanggalAkhir
      return status
    },
    // Validasi Form
    formValidation() {
      const errors = []
      let i = 0
      const formRequired = [
        this.form.nomorSuratKuasa,
        this.form.tanggalSurat,
        // PIHAK PERTAMA
        this.form.namaPihakSatu,
        this.form.jabatanPihakSatu,
        this.form.instansiPihakSatu,
        // this.form.alamatPihakSatu,
        this.form.nipPihakSatu,
        // PIHAK KEDUA
        this.form.namaPihakDua,
        this.form.jabatanPihakDua,
        this.form.instansiPihakDua,
        // this.form.alamatPihakDua,
        this.form.nipPihakDua,
        // KEGIATAN
        this.form.namaKegiatan,
        this.form.judulKegiatan,
        this.form.tanggalAwalKegiatan,
        this.form.tanggalAkhirKegiatan,
        this.form.tempatKegiatan
        // this.listInputMitra.length
      ]
      for (const condition of this.requiredForm) {
        if (['', null].includes(formRequired[i++])) {
          errors.push(condition)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    },
    isValidTanggalAwal() {
      const { validTanggalAwalKegiatan } = this.isError.tanggalKegiatan
      return !validTanggalAwalKegiatan
    },
    isValidTanggalAkhir() {
      const { validTanggalAkhirKegiatan } = this.isError.tanggalKegiatan
      return !validTanggalAkhirKegiatan
    }
  },
  mounted() {
    console.log(this.$route.params.idSk)
    if (!this.$route.params.idSk) this.$router.go(-1)
    this.form.idSk = this.$route.params.idSk
    this.innitData()
  },
  methods: {
    /**
     * Focus input
     */
    inputFocus(data) {
      const refChar = 'ref' // ref = input datalist, !ref = input text
      if (['refTanggalSurat'].includes(data)) {
        /** Flatpickr focus */
        this.$refs[data].$el.nextSibling.focus()
        this.$refs[data].$el._flatpickr.open()
      } else if (['refTanggalAwalKegiatan'].includes(data)) {
        this.$refs[data].$el.nextSibling.focus()
        this.$refs[data].$el._flatpickr.open()
      } else if (['refTanggalAkhirKegiatan'].includes(data)) {
        if (!this.form.tanggalAwalKegiatan) {
          this.$toast.info('Masukkan tanggal mulai kegiatan lebih dulu')
        } else {
          this.$refs[data].$el.nextSibling.focus()
          this.$refs[data].$el._flatpickr.open()
        }
      } else if (data.includes(refChar)) {
        /** Dropdown focus */
        this.$refs[data].focus()
      } else {
        /** Input text focus */
        document.getElementById(data).focus()
      }
    },
    inputTanggalSk() {
      const inputTanggalAwalKegiatan = new Date(this.form.tanggalAwalKegiatan)
      const inputTanggalAkhirKegiatan = new Date(this.form.tanggalAkhirKegiatan)
      if (
        inputTanggalAwalKegiatan.setHours(0, 0, 0, 0) >
          inputTanggalAkhirKegiatan.setHours(0, 0, 0, 0) ||
        inputTanggalAkhirKegiatan.setHours(0, 0, 0, 0) <
          inputTanggalAwalKegiatan.setHours(0, 0, 0, 0)
      ) {
        this.isError.tanggalKegiatan.validTanggalAkhirKegiatan = true
        this.isError.tanggalKegiatan.validTanggalAwalKegiatan = true
      } else {
        this.isError.tanggalKegiatan.validTanggalAwalKegiatan = false
        this.isError.tanggalKegiatan.validTanggalAkhirKegiatan = false
      }
    },
    /**
     * GET => data Detail Kerjasama SK
     */
    async getDataTransaksiSk_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataSuratKuasa?idSk=${this.form.idSk}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataSk = res.data[0]
          this.form = { ...this.dataSk }
          /** Membuat beberapa input form menjadi null */
          this.form.lokasiDokumenSk = null
          // Dokumen KS
          this.getDokumenKs_()
        })
        .catch(err => {
          this.$router.push({
            name: 'kerjasama.jenisDokumen'
          })
          this.$toast.error('Terjadi kesalahan pada sistem')
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * INPUT => Upload Dokumen
     */
    async inputUploadDokumen() {
      if (this.form.lokasiDokumenSk?.type !== 'application/pdf') {
        this.form.lokasiDokumenSk = null
        this.$toast.error('Dokumen harus format PDF (.pdf)')
      }
    },
    /**
     * GET => Dokumen KS
     */
    async getDokumenKs_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenSk?lokasiDokumenSk=${this.dataSk.lokasiDokumenSk}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataDokumenKs = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * SHOW => Dokumen SK
     */
    async showDokumenKs() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenSk?lokasiDokumenSk=${this.dataSk.lokasiDokumenSk}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Dokumen_SK_${this.dataSk.nomorSuratKuasa}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Create Data
     */
    async createData() {
      console.log('form: ', this.form)

      const lokasiDokumenSk =
        this.form.lokasiDokumenSk == null
          ? this.dataSk.lokasiDokumenSk
            ? this.dataSk.lokasiDokumenSk
            : null
          : this.form.lokasiDokumenSk
      console.log(lokasiDokumenSk)

      const bodyFormData = new FormData()
      bodyFormData.append('idSk', this.form.idSk)
      bodyFormData.append('nomorSuratKuasa', this.form.nomorSuratKuasa)
      bodyFormData.append('tanggalSurat', this.form.tanggalSurat)
      bodyFormData.append('namaPihakSatu', this.form.namaPihakSatu)
      bodyFormData.append('jabatanPihakSatu', this.form.jabatanPihakSatu)
      bodyFormData.append('instansiPihakSatu', this.form.instansiPihakSatu)
      // bodyFormData.append('alamatPihakSatu', this.form.alamatPihakSatu)
      bodyFormData.append('nipPihakSatu', this.form.nipPihakSatu)

      bodyFormData.append(
        'namaPihakDua',
        this.form.namaPihakDua ? this.form.namaPihakDua : null
      )
      bodyFormData.append(
        'jabatanPihakDua',
        this.form.jabatanPihakDua ? this.form.jabatanPihakDua : null
      )
      bodyFormData.append(
        'instansiPihakDua',
        this.form.instansiPihakDua ? this.form.instansiPihakDua : null
      )
      // bodyFormData.append(
      //   'alamatPihakDua',
      //   this.form.alamatPihakDua ? this.form.alamatPihakDua : null
      // )
      bodyFormData.append(
        'nipPihakDua',
        this.form.nipPihakDua ? this.form.nipPihakDua : null
      )

      bodyFormData.append('namaKegiatan', this.form.namaKegiatan)
      bodyFormData.append('judulKegiatan', this.form.judulKegiatan)
      bodyFormData.append('tanggalAwalKegiatan', this.form.tanggalAwalKegiatan)
      bodyFormData.append(
        'tanggalAkhirKegiatan',
        this.form.tanggalAkhirKegiatan
      )
      bodyFormData.append('tempatKegiatan', this.form.tempatKegiatan)
      bodyFormData.append('lokasiDokumenSk', this.form.lokasiDokumenSk)
      bodyFormData.append('jamMulaiAcara', '00:00:00')
      bodyFormData.append('jamAkhirAcara', '00:00:00')
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/ubahSuratKuasa`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(() => {
          // this.$toast.success(res.data.message)
          this.$router.push({ name: 'kerjasama.jenisDokumen' })
          this.$toast.success('Data Berhasil Ditambahkan')
        })
        .catch(err => {
          console.log('ERROR', err)
          // this.$toast.error(err.response.data.message)
          this.$toast.error('Data Gagal Ditambahkan')
          throw new Error(err)
        })
    },
    /**
     * Init Data
     */
    async innitData() {
      this.getDataTransaksiSk_()
    }
  },
  data() {
    return {
      /** Required Form -Plan C- */
      requiredForm: [
        { content: 'Nomor Surat Kuasa', model: 'nomorSuratKuasa' },
        { content: 'Tanggal Surat', model: 'refTanggalSurat' },
        // PIHAK PERTAMA
        { content: 'Nama Pihak Pertama', model: 'namaPihakSatu' },
        { content: 'Jabatan Pihak Pertama', model: 'jabatanPihakSatu' },
        { content: 'Instansi Pihak Pertama', model: 'instansiPihakSatu' },
        // { content: 'Alamat Pihak Pertama', model: 'alamatPihakSatu' },
        { content: 'NIP Pihak Pertama', model: 'nipPihakSatu' },
        // PIHAK KEDUA
        { content: 'Nama Pihak Kedua', model: 'namaPihakDua' },
        { content: 'Jabatan Pihak Kedua', model: 'jabatanPihakDua' },
        { content: 'Instansi Pihak Kedua', model: 'instansiPihakDua' },
        // { content: 'Alamat Pihak Kedua', model: 'alamatPihakDua' },
        { content: 'NIP Pihak Kedua', model: 'nipPihakDua' },
        // KEGIATAN
        { content: 'Nama Kegiatan', model: 'namaKegiatan' },
        { content: 'Judul Kegiatan', model: 'judulKegiatan' },
        { content: 'Tanggal Mulai Kegiatan', model: 'refTanggalAwalKegiatan' },
        {
          content: 'Tanggal Selesai Kegiatan',
          model: 'refTanggalAkhirKegiatan'
        },
        { content: 'Tempat Kegiatan', model: 'tempatKegiatan' }
      ],
      isError: {
        tanggalKegiatan: {
          validTanggalAwalKegiatan: false,
          validTanggalAkhirKegiatan: false
        }
      },
      // Others
      dataDokumenKs: null,
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // Mitra
      idSk: null,
      listInputMitra: [],
      // unit
      listInputUnitPelaksana: [],
      // Form
      form: {
        idSk: null,
        nomorSuratKuasa: null,
        tanggalSurat: null,
        namaPihakSatu: null,
        jabatanPihakSatu: null,
        instansiPihakSatu: null,
        // alamatPihakSatu: null,
        nipPihakSatu: null,
        namaPihakDua: null,
        jabatanPihakDua: null,
        instansiPihakDua: null,
        // alamatPihakDua: null,
        nipPihakDua: null,
        namaKegiatan: null,
        judulKegiatan: null,
        tanggalAwalKegiatan: null,
        tanggalAkhirKegiatan: null,
        tempatKegiatan: null,
        lokasiDokumenSk: null
      },
      // Data Sk
      dataSk: {}
    }
  }
}
</script>
